






































































































































































































































































































import {
  SfButton,
  SfCheckbox,
  SfHeading,
  SfInput,
  SfLoader,
  SfSelect,
  SkeletonLoader,
} from '~/components';

import {
  ref,
  computed,
  watch,
  onMounted,
  defineComponent,
} from '@nuxtjs/composition-api';

import {
  useShipping,
  useUser,
  useUserAddress,
  useCart,
  useCountrySearch,
  useGetShippingMethods,
  useBilling,
  useWebsiteCode,
  useAddresses,
  CountryCodeEnum
} from '~/composables';

import type {
  Country,
  AvailableShippingMethod,
  CustomerAddress,
  Customer,
  GiftWrapConfigInterface,
} from '~/modules/GraphQL/types';

import {
  addressFromApiToForm,
  CheckoutAddressForm,
  findUserAddressIdenticalToSavedCartAddress,
  getInitialCheckoutAddressForm,
} from '~/helpers/checkout/address';

import userShippingGetters from '~/modules/customer/getters/userShippingGetters';
import userBillingGetters from '~/modules/customer/getters/userBillingGetters';
import addressGetter from '~/modules/customer/getters/addressGetter';
import cartGetters from '~/modules/checkout/getters/cartGetters';

import { mergeItem } from '~/helpers/asyncLocalStorage';

import { ValidationObserver, ValidationProvider, setInteractionMode, extend } from 'vee-validate';
import { required, min, digits } from 'vee-validate/dist/rules';
import { useConfigStore } from '~/stores/config';

import Multiselect from 'vue-multiselect';
import * as phoneCountries from '~/utilities/countries-phone-masks';
import Default from '~/components/templates/form/Form.vue';

setInteractionMode('eager');
extend('required', {
  ...required,
  message: 'This field is required',
});
extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});
extend('digits', {
  ...digits,
  message: 'Please provide a valid phone number',
});

export default defineComponent({
  name: 'Shipping',
  components: {
    SfLoader,
    Default,
    SfCheckbox,
    SfHeading,
    SfInput,
    SfButton,
    SfSelect,
    ValidationProvider,
    ValidationObserver,
    UserShippingAddresses: () => import('~/modules/checkout/components/UserShippingAddresses.vue'),
    VsfShippingProvider: () => import('~/modules/checkout/components/VsfShippingProvider.vue'),
    Multiselect,
    SkeletonLoader,
  },
  props: {
    lastName: { type: String, default: '' },
    firstName: { type: String, default: '' },
    isShowPayment: { type: Boolean, default: false },
    isOrderProcessing: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const {
      load: loadShipping,
      save: saveShipping,
      loading: isShippingLoading,
      giftWrapConfig: loadGiftWrapConfig,
      setGiftWrapOnCart,
      unsetGiftWrapOnCart,
    } = useShipping();

    const {
      load: loadShippingMethods,
      loading: isLoadingShippingMethods
    } = useGetShippingMethods();

    const {
      load: loadUserShipping,
      loading: loadingUserShipping,
      setDefaultAddress,
    } = useUserAddress();

    const {
      save: saveAddress,
      load: loadAddresses,
      loading: isLoadingAddress
    } = useAddresses();

    const {
      load: loadCountries,
      search: searchCountry,
    } = useCountrySearch();

    const { save: saveBilling, loading: loadingBilling } = useBilling();
    const { isAuthenticated } = useUser();
    const { isAu } = useWebsiteCode();
    const { cart, load } = useCart();

    const userShipping = ref<Customer | null>(null);
    const countries = ref<Country[]>([]);
    const country = ref<Country | null>(null);
    const shippingDetails = ref<CheckoutAddressForm>(getInitialCheckoutAddressForm());
    const shippingMethods = ref<AvailableShippingMethod[]>([]);
    const currentAddressId = ref<number | null>(null);
    const sameAsShipping = ref(true);
    const isSaveNewAddress = ref(false);
    const setAsDefault = ref(false);
    const isSetAsDefaultAddress = ref(false);
    const userBilling = ref<Customer | null>(null);
    const billingDetails = ref<CheckoutAddressForm>(getInitialCheckoutAddressForm());
    const isSetAsDefaultRequested = ref(false);
    const isFormSubmitted = ref(false);
    const isAddNewAddressFormVisible = ref(true);
    const isShippingDetailsStepCompleted = ref(false);
    const shippingRef = ref(null);
    const giftWrapConfig = ref<GiftWrapConfigInterface>(null);

    const addresses = computed(() => userShippingGetters.getAddresses(userShipping.value));
    const storeCountryId = computed(() => useConfigStore().storeConfig.store_information?.country_id);
    const phoneFormatData = ref();

    const hasSavedShippingAddress = computed(() => {
      if (!isAuthenticated.value || !userShipping.value) {
        return false;
      }
      return addresses.value.length > 0;
    });
    const countriesList = computed(() => addressGetter.countriesList(countries.value));
    const regionInformation = computed(() => addressGetter.regionList(country.value));

    const handleBillingAddressSubmit = (reset: () => void) => async () => {
      let addressId = currentAddressId.value;
      if (addressId === undefined) {
        addressId = null;
      }
      const billingDetailsData = {
        billingDetails: {
          ...billingDetails.value,
          lastname: props.lastName,
          firstname: props.firstName,
          customerAddressId: addressId === null ? null : String(addressId),
          sameAsShipping: sameAsShipping.value,
          save_in_address_book: false,
        },
      };
      await saveBilling(billingDetailsData);
      if (addressId !== null && setAsDefault.value) {
        const [chosenAddress] = userBillingGetters.getAddresses(
          userBilling.value,
          { id: addressId },
        );
        chosenAddress.default_billing = setAsDefault.value;
        if (chosenAddress) {
          await setDefaultAddress({ address: chosenAddress });
          userBilling.value = await loadUserShipping(true);
        }
      }
      reset();
      await mergeItem('checkout', { billing: billingDetailsData });
      emit('shipping-and-billing-submit', true);
    };

    const handleAddressSubmit = (reset: () => void) => async () => {
      if (isSaveNewAddress.value) {
        const regionId = country.value?.available_regions?.find((region) => region.code === shippingDetails.value.region) ?? null;

        const regionInfoIfNoRegionInfoFromBackend = {
          region: shippingDetails.value.region,
          region_code: '',
          region_id: null
        };

        const newAddress = {
          street: shippingDetails.value.street,
          apartment: shippingDetails.value.apartment,
          city: shippingDetails.value.city,
          region: regionId
            ? { region_id: regionId.id }
            : regionInfoIfNoRegionInfoFromBackend,
          country_code: shippingDetails.value.country_code as CountryCodeEnum,
          postcode: shippingDetails.value.postcode,
          telephone: shippingDetails.value.telephone,
          default_shipping: isSetAsDefaultAddress.value,
          default_billing: false,
          firstname: props.firstName,
          lastname: props.lastName,
        };
        // @ts-ignore
        await saveAddress({ address: newAddress });
        await loadAddresses(); //TODO
        userShipping.value = await loadUserShipping();
        isSaveNewAddress.value = false;
      }
      const addressId = currentAddressId.value;
      const shippingDetailsData = {
        ...shippingDetails.value,
        lastname: props.lastName,
        firstname: props.firstName,
        customerAddressId: addressId,
        save_in_address_book: false,
      };
      await mergeItem('checkout', { shipping: shippingDetailsData });
      const shippingInfo = await saveShipping({ shippingDetails: shippingDetailsData });
      shippingMethods.value = shippingInfo?.available_shipping_methods ?? [];

      if (addressId !== null && isSetAsDefaultRequested.value) {
        const [chosenAddress] = userShippingGetters.getAddresses(
          userShipping.value,
          { id: addressId },
        );
        chosenAddress.default_shipping = isSetAsDefaultRequested.value;
        if (chosenAddress) {
          await setDefaultAddress({ address: chosenAddress });
          userShipping.value = await loadUserShipping(true);
        }
      }
      reset();
      isShippingDetailsStepCompleted.value = true;
    };

    const handleAddNewAddressBtnClick = () => {
      currentAddressId.value = null;
      shippingDetails.value = getInitialCheckoutAddressForm();
      isAddNewAddressFormVisible.value = true;
      isShippingDetailsStepCompleted.value = false;
      emit('shipping-and-billing-submit', false);
    };

    const handleSetCurrentAddress = async (customerAddress: CustomerAddress) => {
      const id = customerAddress?.id;
      currentAddressId.value = id;
      if (id) {
        isAddNewAddressFormVisible.value = false;
      }
      shippingDetails.value = addressFromApiToForm(customerAddress);
      country.value = customerAddress.country_code ? await searchCountry({ id: customerAddress.country_code }) : null;
      isShippingDetailsStepCompleted.value = false;
    };

    const changeShippingDetails = (field: keyof CheckoutAddressForm, value: string) => {
      shippingDetails.value[field] = value;
      isShippingDetailsStepCompleted.value = false;
      currentAddressId.value = null;

      if (field === 'telephone') {
        if (value.length > 1) {
          const filteredCountries = phoneCountries.filter(item => value.startsWith(item.code));
          if (filteredCountries.length) {
            phoneFormatData.value = filteredCountries[0];
          }
        } else {
          phoneFormatData.value = null;
        }
      }
    };

    const changeCountry = async (id: string) => {
      changeShippingDetails('country_code', id);
      const newCountry = await searchCountry({ id });
      shippingDetails.value.region = '';
      shippingDetails.value.telephone = '';
      country.value = newCountry;
      phoneFormatData.value = phoneCountries.find(({ iso }) => iso === id);
      shippingDetails.value.telephone = phoneFormatData.value.code;
    };

    const selectedShippingMethod = computed(() => cartGetters.getSelectedShippingMethod(cart.value));

    const cartItemQty = computed(() => cartGetters.getTotalItems(cart.value));

    watch(
      () => phoneFormatData.value,
      () => {
        if (phoneFormatData.value) {
          const maskValue = phoneFormatData.value.mask
            .reduce((acc, cur) => [...acc, { mask: `${phoneFormatData.value.code.replace(/[0-9]/g, 0)} ${cur}` }], []);
        }
      }
    );

    watch(
      () => [selectedShippingMethod.value, props.isOrderProcessing],
      async (newVal, isOrderProcessing) => {
        if (newVal && !isOrderProcessing) {
          shippingMethods.value = await loadShippingMethods({ cartId: cart.value?.id });
        }
      }, { immediate: true });

    watch(
      () => [cartItemQty.value, props.isOrderProcessing],
      async (newQty, isOrderProcessing) => {
        if (newQty && !isOrderProcessing) {
          shippingMethods.value = await loadShippingMethods({ cartId: cart.value?.id });
        }
      });

    watch(
      () => (shippingRef.value !== null && shippingRef.value.flags !== null ? shippingRef.value.flags.dirty : null),
      (newVal) => {
        if (newVal) {
          emit('shipping-and-billing-submit', false);
        }
      }
    );

    watch(
      currentAddressId,
      () => {
        emit('shipping-and-billing-submit', false);
      }
    );

    onMounted(async () => {
      const [loadedShippingInfoBoundToCart, loadedUserShipping, loadedCountries, loadedGiftWrapConfig] = await Promise.all([
        loadShipping(),
        loadUserShipping(),
        loadCountries(),
        loadGiftWrapConfig()
      ]);

      const [defaultAddress = null] = userShippingGetters.getAddresses(loadedUserShipping, { default_shipping: true });
      const wasShippingAddressAlreadySetOnCart = Boolean(loadedShippingInfoBoundToCart);

      if (wasShippingAddressAlreadySetOnCart) {
        const userAddressIdenticalToSavedCartAddress = findUserAddressIdenticalToSavedCartAddress(
          loadedUserShipping?.addresses,
          loadedShippingInfoBoundToCart,
        );
        await handleSetCurrentAddress({
          ...loadedShippingInfoBoundToCart,
          id: userAddressIdenticalToSavedCartAddress?.id
        });
      } else if (defaultAddress) {
        await handleSetCurrentAddress(defaultAddress);
      }

      if (shippingDetails.value?.country_code) {
        country.value = await searchCountry({ id: shippingDetails.value.country_code });
      }

      userShipping.value = loadedUserShipping;
      countries.value = loadedCountries;

      if (storeCountryId.value) {
        await changeCountry(storeCountryId.value);
      }

      if (loadedGiftWrapConfig) {
        giftWrapConfig.value = loadedGiftWrapConfig;
      }

      if (isAuthenticated) {
        currentAddressId.value = userShipping.value?.addresses?.find(address => address.default_shipping === true)?.id || null;
      }
    });

    const handleGiftWrap = async (isGiftWrapEnable) => {
      if (isGiftWrapEnable) {
        await setGiftWrapOnCart(cart.value?.id);
        await load();
      } else {
        await unsetGiftWrapOnCart(cart.value?.id);
        await load();
      }
    };

    return {
      isAddNewAddressFormVisible,
      changeCountry,
      changeShippingDetails,
      countries,
      countriesList,
      country,
      currentAddressId,
      handleAddNewAddressBtnClick,
      handleAddressSubmit,
      handleSetCurrentAddress,
      hasSavedShippingAddress,
      isAuthenticated,
      isFormSubmitted,
      isShippingDetailsStepCompleted,
      isShippingLoading,
      isLoadingShippingMethods,
      regionInformation,
      searchCountry,
      isSetAsDefaultRequested,
      shippingDetails,
      shippingMethods,
      addresses,
      shippingRef,
      handleBillingAddressSubmit,
      isAu,
      phoneCountries,
      phoneFormatData,
      isSaveNewAddress,
      isSetAsDefaultAddress,
      giftWrapConfig,
      handleGiftWrap,
      isLoadingAddress,
      loadingUserShipping,
      loadingBilling
    };
  },
});
